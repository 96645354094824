// frontend/src/App.js

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { ShareUrlProvider } from "./context/ShareUrlContext";
import UploadPage from "./components/UploadPage";
import ViewPage from "./components/ViewPage";
import Auth from "./components/Auth";
import SignUp from "./components/SignUp";
import AdminDashboard from "./components/AdminDashboard";
import Navigation from "./components/Navigation";
import ImageGallery from "./components/ImageGallery";
import ErrorPage from "./components/ErrorPage";
import PasswordReset from "./components/PasswordReset";
import ResetPasswordFinish from "./components/ResetPasswordFinish";
import LandingPage from "./components/LandingPage";
import FooterComponent from "./components/FooterComponent";
import TermsOfService from "./components/TermsOfService";

function AppRoutes() {
  const { currentUser, loading, userStatus } = useAuth();
  const location = useLocation();

  const hideFooterPaths = ["/view", "/view/:sharedId"];

  const shouldHideFooter = hideFooterPaths.some((path) => {
    if (path.includes(":")) {
      const basePath = path.split("/:")[0];
      return location.pathname.startsWith(basePath);
    }
    return location.pathname === path;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <main className="main-content">
        <Routes>
          <Route
            path="/login"
            element={
              currentUser && userStatus === "active" ? (
                <Navigate to="/upload" replace />
              ) : (
                <Auth />
              )
            }
          />
          <Route
            path="/signup"
            element={
              currentUser && userStatus === "active" ? (
                <Navigate to="/upload" replace />
              ) : (
                <SignUp />
              )
            }
          />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route
            path="/upload"
            element={
              currentUser && userStatus === "active" ? (
                <>
                  <Navigation />
                  <UploadPage />
                </>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/view"
            element={
              currentUser && userStatus === "active" ? (
                <ViewPage />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/view/:sharedId" element={<ViewPage />} />
          <Route
            path="/gallery"
            element={
              currentUser && userStatus === "active" ? (
                <>
                  <Navigation />
                  <ImageGallery />
                </>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/admin"
            element={
              currentUser && currentUser.role === "admin" ? (
                <>
                  <Navigation />
                  <AdminDashboard />
                </>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/reset-password-finish"
            element={<ResetPasswordFinish />}
          />
        </Routes>
      </main>
      {!shouldHideFooter && <FooterComponent />}
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <ShareUrlProvider>
        <Router>
          <AppRoutes />
        </Router>
      </ShareUrlProvider>
    </AuthProvider>
  );
}

export default App;
