import { createNoise2D } from "simplex-noise";

const noise2D = createNoise2D();

export const SPEED_SETTINGS = {
  slow: 0.3,
  medium: 0.5,
  fast: 0.7,
};

export const SIZE_SETTINGS = {
  small: 0.5,
  medium: 1,
  large: 1.5,
};

export const MOVEMENT_SETTINGS = {
  normal: (time, amplitude, offset = 0, phaseOffset = 0) => ({
    x: noise2D(time * 0.001 + offset, phaseOffset) * amplitude * 0.1,
    y: noise2D(time * 0.001 + offset + 1000, phaseOffset) * amplitude * 0.1,
  }),
  sway: (time, amplitude, offset = 0, phaseOffset = 0) => ({
    x:
      Math.sin((time + offset) * 0.002 + phaseOffset) * amplitude * 0.03 +
      noise2D(time * 0.001 + offset, phaseOffset) * amplitude * 0.05,
    y: noise2D(time * 0.001 + offset + 1000, phaseOffset) * amplitude * 0.05,
  }),
  spin: (time, amplitude, offset = 0, phaseOffset = 0) => {
    const baseRotationSpeed = 1000;
    const rotationSpeed = baseRotationSpeed * (1 + amplitude);
    return {
      rotation:
        ((((time + offset) * rotationSpeed) / 1000 + phaseOffset) % 360) +
        noise2D(time * 0.001 + offset, phaseOffset) * 10,
    };
  },
  stretch: (time, amplitude, offset = 0, phaseOffset = 0) => {
    const stretch =
      Math.sin((time + offset) * 0.002 + phaseOffset) * 0.4 * amplitude +
      1 +
      noise2D(time * 0.001 + offset, phaseOffset) * 0.5;
    return {
      scaleX: stretch,
      scaleY: 1 / stretch,
      x: noise2D(time * 0.001 + offset + 2000, phaseOffset) * amplitude * 0.3,
      y: noise2D(time * 0.001 + offset + 3000, phaseOffset) * amplitude * 0.3,
    };
  },
  vibrate: (time, amplitude, offset = 0, phaseOffset = 0) => {
    const frequency = 0.5; // 振動の頻度
    const vibrateAmount = amplitude * 0.15; // 振動の大きさ
    return {
      x: Math.sin((time + offset) * frequency + phaseOffset) * vibrateAmount,
      y:
        Math.cos((time + offset) * frequency + phaseOffset + 1000) *
        vibrateAmount,
      rotation: Math.sin((time + offset) * frequency * 1.5 + phaseOffset) * 2,
    };
  },
  tilt: (time, amplitude, offset = 0, phaseOffset = 0) => {
    const tiltFrequency = 0.005; // 傾きの速さを調整
    const tiltAngle = Math.sin((time + offset) * tiltFrequency + phaseOffset) * amplitude * 15; // 傾きの角度を計算
    return {
      rotation: tiltAngle,
    };
  },
};
