import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSignUp } from "../hooks/useSignUp";
import HeaderComponent from "./HeaderComponent";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { signUp, isLoading, error, success } = useSignUp();

  const handleSignUp = async (e) => {
    e.preventDefault();
    await signUp(email, password);
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success, navigate, isLoading, error]);

  return (
    <>
      <HeaderComponent showSignUpButton={false} />
      <div className="auth-page">
        <div className="container">
          <div className="auth-form">
            <h2>アカウント作成</h2>
            {error && (
              <p
                className="message error"
                style={{
                  color: "red",
                  backgroundColor: "#f8f8f8",
                  padding: "10px",
                  borderRadius: "5px",
                  marginBottom: "15px",
                }}
              >
                {error}
              </p>
            )}
            {success && (
              <p
                className="message success"
                style={{
                  color: "green",
                  backgroundColor: "#f8f8f8",
                  padding: "10px",
                  borderRadius: "5px",
                  marginBottom: "15px",
                }}
              >
                アカウントが作成されました。管理者による承認をお待ち下さい。ログインページに移動します。
              </p>
            )}
            <form onSubmit={handleSignUp}>
              <div className="form-group">
                <label htmlFor="email">メールアドレス</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isLoading}
                  autoComplete="username"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">パスワード</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={isLoading}
                  autoComplete="new-password"
                />
              </div>
              <div className="password-rules">
                <p>
                  パスワードは8文字以上で、大文字・小文字・数字・記号（!、_、-、@、$、&、%）を各1つ以上含めてください。
                </p>
              </div>
              <button type="submit" disabled={isLoading}>
                {isLoading ? "処理中..." : "アカウント作成"}
              </button>
            </form>
            <p className="toggle-auth">
              すでにアカウントをお持ちですか？ <Link to="/login">ログイン</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
