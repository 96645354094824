import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiBaseUrl from "../utils/apiBaseUrl";
import HeaderComponent from "./HeaderComponent";

export default function LandingPage() {
  const [openFaqs, setOpenFaqs] = useState([]);
  const contactRef = useRef(null);

  // フォームの状態を管理
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  // 入力変更時のハンドラー
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // フォーム送信時のハンドラー
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiBaseUrl}/api/contact`, formData);
      alert(
        "お問い合わせが送信されました。ありがとうございます。担当者からの返信をお待ち下さい"
      );
      setFormData({
        name: "",
        company: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("お問い合わせの送信に失敗しました。", error);
      if (error.response) {
        console.error("サーバーからのレスポンス:", error.response.data);
      }
      alert("お問い合わせの送信に失敗しました。再度お試しください。");
    }
  };

  // 既存のコード
  const toggleFaq = (index) => {
    setOpenFaqs((prevOpenFaqs) => {
      if (prevOpenFaqs.includes(index)) {
        return prevOpenFaqs.filter((i) => i !== index);
      } else {
        return [...prevOpenFaqs, index];
      }
    });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const faqData = [
    {
      question: "手持ちのパソコンを使うことはできますか？",
      answer:
        "可能です。ネット環境があれば、パソコンやスマートフォンからアクセスして実施して頂けます。",
    },
    {
      question:
        "手持ちのディスプレイやプロジェクタを利用することはできますか？",
      answer:
        "スクリーン表示用のURLを使用しますので、ブラウザ表示機能(環境)があれば可能です。",
    },
    {
      question: "スキャナは必要ですか？",
      answer:
        "スキャナがある方が取り込み後のぬり絵が、鮮明になりやすいです。スマートフォンのカメラでも取込は可能です。",
    },
    {
      question:
        "オリジナルのデザインやキャラクター、背景を使用することは出来ますか？",
      answer:
        "可能です。自作される場合は、枠線のおすすめ等説明させて頂きますので、お問い合わせ下さい。弊社で作成代行も行えます。",
    },
  ];

  return (
    <div className="landing-page">
      <HeaderComponent onSignUpClick={scrollToContact} />
      <main className="landing-main">
        <div className="hero-section">
          <div className="inner">
            <div className="hero-content">
              <img
                src="/images/lp/nuriemon.png"
                alt="Meriemon Service Title"
                className="hero-title"
              />
              <button className="button cta-button" onClick={scrollToContact}>
                お問い合わせ・申請はこちら
              </button>
            </div>
            <div className="hero-image">
              <img
                src="/images/lp/nuriemon_c.png"
                alt="Meriemon character"
                className="character-image"
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <div className="inner">
            <div className="info-content">
              <h2>子供たちの笑顔と体験を創出する、体験型コンテンツ</h2>
              <p>
                お子様が塗った絵をその場でスキャンし、スクリーンに投影すると絵がアニメーションとして動き出します。
              </p>
              <p>
                商業施設やイベント会場での集客力アップや顧客満足度向上に貢献する、体験型エンターテインメントサービスです。
              </p>
            </div>
          </div>
        </div>

        <div className="faq-section">
          <div className="inner">
            <h2>よくある質問</h2>
            <div className="faq-accordion">
              {faqData.map((faq, index) => (
                <div key={index} className="faq-item">
                  <h3
                    onClick={() => toggleFaq(index)}
                    className={`faq-question ${
                      openFaqs.includes(index) ? "open" : ""
                    }`}
                  >
                    {faq.question}
                  </h3>
                  <div
                    className={`faq-answer ${
                      openFaqs.includes(index) ? "open" : ""
                    }`}
                  >
                    <div className="faq-answer-inner">
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="contact-section" ref={contactRef}>
          <div className="inner">
            <h2>お問い合わせ</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">
                  氏名 <span className="required">（必須）</span>
                </label>
                <input
                  id="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">会社名</label>
                <input
                  id="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  メールアドレス <span className="required">（必須）</span>
                </label>
                <input
                  id="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">電話番号</label>
                <input
                  id="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  お問い合わせ内容 <span className="required">（必須）</span>
                </label>
                <textarea
                  id="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <p className="consent">
                <a
                  href="https://greensnap.jp/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  プライバシーポリシー
                </a>
                に同意して
              </p>
              <button type="submit" className="button submit-button">
                送信
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}
