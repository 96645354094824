import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import {
  SPEED_SETTINGS,
  SIZE_SETTINGS,
  MOVEMENT_SETTINGS,
} from "./animationSettings";
import { useAuth } from "../context/AuthContext";
import confetti from "canvas-confetti";
import { createNoise2D } from "simplex-noise";
import AudioPermissionDialog from "./AudioPermissionDialog";
const noise2D = createNoise2D();

// 特殊な動きを管理する関数
const applySpecialMovement = (image, currentTime) => {
  if (!image.specialMovement) {
    if (image.specialMovementCooldown <= 0 && Math.random() < 0.0001) {
      // 特殊動きの発生確率を調整し、クールダウンを設定
      const type = Math.floor(Math.random() * 5);
      image.specialMovement = {
        type: type,
        startTime: currentTime,
        duration: 300 + Math.random() * 400, // 0.3-0.7秒
        originalVelocityX: image.velocityX,
        originalVelocityY: image.velocityY,
        originalY: image.y,
      };
      image.specialMovementCooldown = 10000; // 10秒のクールダウン
    } else {
      image.specialMovementCooldown = (image.specialMovementCooldown || 0) - 16; // フレームごとに減少
    }
  }

  if (image.specialMovement) {
    const elapsedTime = currentTime - image.specialMovement.startTime;
    const progress = Math.min(elapsedTime / image.specialMovement.duration, 1);

    switch (image.specialMovement.type) {
      case 0: // 急回転
        image.rotation += 360 * Math.sin(progress * Math.PI); // 1回転の往復
        break;
      case 1: // より緩やかな加速と減速
        const maxSpeedMultiplier = 1.3;
        const speedCurve = Math.sin(progress * Math.PI);
        const currentSpeedMultiplier =
          1 + (maxSpeedMultiplier - 1) * speedCurve;

        // 元の速度に対して倍率を適用
        image.velocityX =
          image.specialMovement.originalVelocityX * currentSpeedMultiplier;
        image.velocityY =
          image.specialMovement.originalVelocityY * currentSpeedMultiplier;
        break;
      case 2: // 急激なサイズ変化
        const peakScale = 2; // 最大◯倍まで大きくなる
        const sharpness = 1; // この値を大きくすると、ピークがより鋭くなる
        const sizeChange =
          1 + (peakScale - 1) * Math.sin(progress * Math.PI) ** sharpness;
        image.specialScale = sizeChange;
        break;
      case 3: // Z軸回転
        const rotationProgress = Math.sin(progress * Math.PI);
        image.zRotation = 360 * rotationProgress;
        image.scale = 1 - 0.5 * Math.abs(rotationProgress); // スケールを変更して3D感を出す
        break;
      case 4: // ジャンプ
        const jumpHeight = 20; // ジャンプの高さ（画面の20%）
        const jumpProgress = Math.sin(progress * Math.PI);
        const verticalOffset = jumpHeight * jumpProgress;
        image.y = image.specialMovement.originalY - verticalOffset;
        break;
      default:
        break;
    }

    if (progress >= 1) {
      // 速度を元に戻す
      image.velocityX = image.specialMovement.originalVelocityX;
      image.velocityY = image.specialMovement.originalVelocityY;

      image.specialMovement = null;
      image.zRotation = 0;
      image.rotation = 0;
      image.specialScale = 1;
      image.specialEffect = null;
    }
  }

  // 移動距離を制限（画面の20%以内に収める）
  image.x = Math.max(0, Math.min(100, image.x));
  image.y = Math.max(0, Math.min(100, image.y));

  return image;
};

function ViewPage() {
  const { sharedId } = useParams();
  const [isSharedViewValid, setIsSharedViewValid] = useState(true);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [backgroundFileType, setBackgroundFileType] = useState(null);
  const [error, setError] = useState(null);
  const [groundPosition, setGroundPosition] = useState(50);
  const [userId, setUserId] = useState(null);
  const { currentUser } = useAuth();
  const imagesRef = useRef({});
  const animationRef = useRef();
  const [newImageState, setNewImageState] = useState({ id: null, state: null });
  const [showAudioPermissionDialog, setShowAudioPermissionDialog] =
    useState(true);
  const [bgmAudio, setBgmAudio] = useState(null);
  const [effectAudio, setEffectAudio] = useState(null);
  const [userAllowedAudio, setUserAllowedAudio] = useState(false);

  // 非表示時間を設定
  const [deletionTime, setDeletionTime] = useState("unlimited");
  const [visibleImages, setVisibleImages] = useState({});
  const timersRef = useRef({});
  const hideImage = useCallback((imageId) => {
    setVisibleImages((prev) => ({ ...prev, [imageId]: false }));
  }, []);

  const setupImageTimer = useCallback(
    (image) => {
      if (deletionTime === "unlimited") return;

      const currentTime = Date.now();
      const uploadTime = image.timestamp.toMillis();
      const timeDiff = (currentTime - uploadTime) / 60000; // 分単位の差
      const remainingTime = Math.max(
        0,
        (parseInt(deletionTime) - timeDiff) * 60 * 1000
      );

      if (remainingTime > 0) {
        if (timersRef.current[image.id]) {
          clearTimeout(timersRef.current[image.id]);
        }
        timersRef.current[image.id] = setTimeout(
          () => hideImage(image.id),
          remainingTime
        );
      } else {
        hideImage(image.id);
      }
    },
    [deletionTime, hideImage]
  );

  // initializeImage: 新しい画像オブジェクトを初期化する関数
  const initializeImage = useCallback(
    (data) => {
      const isNewImage = data.id === newImageState.id;
      return {
        id: data.id,
        ...data,
        x: isNewImage ? 50 : Math.random() * 80 + 10,
        y: isNewImage ? 50 : Math.random() * 80 + 10,
        velocityX: 0,
        velocityY: 0,
        scale: isNewImage ? 0.1 : 1,
        directionChangeTimer: Math.random() * 200 + 100,
        rotation: 0,
        offset: Math.random() * 10000,
        phaseOffset: Math.random() * Math.PI * 2,
        lastMovementUpdate: Date.now(),
        nextMovementUpdate: Date.now() + Math.random() * 5000,
        globalScale: 1,
        scaleDirection: Math.random() < 0.5 ? 1 : -1,
        scaleSpeed: Math.random() * 0.001 + 0.0005,
        animationStartTime: Date.now(),
        isNewImage: isNewImage,
        specialMovementCooldown: 0,
      };
    },
    [newImageState]
  );

  const newImageStateRef = useRef(newImageState);

  useEffect(() => {
    newImageStateRef.current = newImageState;
  }, [newImageState]);

  useEffect(() => {
    const fetchAudioSettings = async () => {
      if (userId) {
        const userSettingsRef = doc(db, "userSettings", userId);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.bgmUrl) {
            const audio = new Audio(data.bgmUrl);
            audio.loop = true;
            setBgmAudio(audio);
          }
          if (data.effectSoundUrl) {
            const audio = new Audio(data.effectSoundUrl);
            setEffectAudio(audio);
          }
        }
      }
    };
    fetchAudioSettings();
  }, [userId]);

  const playAudio = useCallback(() => {
    if (bgmAudio) {
      bgmAudio
        .play()
        .then(() => {})
        .catch((error) => {});
    }
  }, [bgmAudio]);

  const playEffectSound = useCallback(() => {
    if (effectAudio) {
      effectAudio.currentTime = 0;
      effectAudio
        .play()
        .then(() => {})
        .catch((error) => {});
    }
  }, [effectAudio]);

  useEffect(() => {
    const validateSharedView = async () => {
      if (sharedId) {
        try {
          const userSettingsQuery = query(
            collection(db, "userSettings"),
            where("sharedViewId", "==", sharedId)
          );
          const querySnapshot = await getDocs(userSettingsQuery);
          if (querySnapshot.empty) {
            navigate("/error", {
              state: {
                message:
                  "共有ビューが見つかりません。削除された可能性があります。",
              },
            });
          } else {
            const sharedViewData = querySnapshot.docs[0].data();
            const sharedUserId =
              sharedViewData.userId || querySnapshot.docs[0].id;
            setUserId(sharedUserId);
            setDeletionTime(sharedViewData.deletionTime || "unlimited");
            setGroundPosition(sharedViewData.groundPosition || 50);

            // 背景とBGMの情報を設定
            if (sharedViewData.backgroundFileUrl) {
              setBackgroundFile(sharedViewData.backgroundFileUrl);
              setBackgroundFileType(sharedViewData.backgroundFileType);
            }
            if (sharedViewData.bgmUrl) {
              const audio = new Audio(sharedViewData.bgmUrl);
              audio.loop = true;
              setBgmAudio(audio);
            }
          }
        } catch (err) {
          navigate("/error", {
            state: {
              message: "共有ビューの読み込み中にエラーが発生しました。",
            },
          });
        }
      } else if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        navigate("/login");
      }
    };

    validateSharedView();
  }, [sharedId, currentUser, navigate]);

  // useEffect: コンポーネントのマウント時にFirestoreからデータを取得し、画像の状態を更新
  useEffect(() => {
    if (!userId) return;

    const fetchUserSettings = async () => {
      const userSettingsRef = doc(db, "userSettings", userId);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setDeletionTime(data.deletionTime || "unlimited");
        setGroundPosition(data.groundPosition || 50);
      }
    };
    fetchUserSettings();

    const q = query(
      collection(db, "processedImages"),
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      limit(100)
    );

    let initialLoad = true;
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const newImages = querySnapshot.docs.map((doc) => {
          const data = { id: doc.id, ...doc.data() };
          return imagesRef.current[doc.id]
            ? { ...imagesRef.current[doc.id], ...data }
            : initializeImage(data);
        });

        const previousImagesLength = Object.keys(imagesRef.current).length;

        imagesRef.current = newImages.reduce((acc, img) => {
          acc[img.id] = img;
          return acc;
        }, {});

        setImages(newImages);

        // 新しい画像に対して表示状態を設定し、タイマーをセットアップ
        const newVisibleImages = {};
        newImages.forEach((image) => {
          newVisibleImages[image.id] = true;
          setupImageTimer(image);
        });
        setVisibleImages(newVisibleImages);

        if (!initialLoad && newImages.length > previousImagesLength) {
          const newImageId = newImages[0].id;
          setNewImageState({ id: newImageId, state: "animating" });
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });
          if (userAllowedAudio && effectAudio) {
            playEffectSound();
          }
        }

        initialLoad = false;
      },
      (err) => {
        setError(err.message);
      }
    );

    const fetchBackgroundFile = async () => {
      try {
        const userSettingsRef = doc(db, "userSettings", userId);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists() && docSnap.data().backgroundFileUrl) {
          setBackgroundFile(docSnap.data().backgroundFileUrl);
          setBackgroundFileType(docSnap.data().backgroundFileType);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchBackgroundFile();

    return () => {
      unsubscribe();
      // すべてのタイマーをクリア
      Object.values(timersRef.current).forEach(clearTimeout);
    };
  }, [
    userId,
    initializeImage,
    setupImageTimer,
    userAllowedAudio,
    playEffectSound,
    effectAudio,
  ]);

  useEffect(() => {
    images.forEach(setupImageTimer);
  }, [deletionTime, setupImageTimer, images]);

  // getSpeed: 速度設定を数値に変換する関数
  const getSpeed = (speedSetting) => {
    if (typeof speedSetting === "number") {
      return speedSetting;
    } else if (typeof speedSetting === "string") {
      return SPEED_SETTINGS[speedSetting] || SPEED_SETTINGS.medium;
    }
    return SPEED_SETTINGS.medium;
  };

  // moveImage: 個々の画像の動きを計算する関数
  const moveImage = useCallback(
    (image) => {
      if (!imagesRef.current[image.id]) {
        imagesRef.current[image.id] = {};
      }
      const imageRef = imagesRef.current[image.id];

      if (imageRef.lastMovement !== image.movement) {
        imageRef.lastMovement = image.movement;
        imageRef.rotationResetStart = image.rotation || 0;
        imageRef.rotationResetProgress = 0;
      }

      // エモート処理
      const textEmotes = [
        "❤️",
        "⭐",
        "😊",
        "✨",
        "😁",
        "😍",
        "😗",
        "😝",
        "🤗",
        "🥳",
        "💞",
        "💖",
        "💭",
      ];
      const svgEmotes = ["good", "Hello", "hi", "wow"];
      if (Math.random() < 0.001) {
        if (Math.random() < 0.3) {
          image.emote = {
            type: "text",
            content: textEmotes[Math.floor(Math.random() * textEmotes.length)],
          };
        } else {
          image.emote = {
            type: "svg",
            content: svgEmotes[Math.floor(Math.random() * svgEmotes.length)],
          };
        }
        image.emoteTimer = 150;
      } else if (image.emoteTimer > 0) {
        image.emoteTimer--;
      } else {
        image.emote = null;
      }

      const currentTime = Date.now();
      const currentSpeed = getSpeed(image.speed);
      const amplitudeFactor = SIZE_SETTINGS[image.size] || SIZE_SETTINGS.medium;

      // 現在の位置を保存
      const oldX = image.x;
      const oldY = image.y;

      const isNewImage = image.id === newImageStateRef.current.id;

      if (isNewImage && !image.animationCompleted) {
        const animationDuration = 1500; // 1.5秒間のアニメーション
        const elapsedTime = currentTime - image.animationStartTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);

        if (progress < 0.5) {
          // 最初の0.75秒：スケールアップ
          const scaleUpProgress = progress * 2; // 0-1に正規化
          const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);
          const easedProgress = easeOutCubic(scaleUpProgress);
          image.scale = 1.1 + 5.9 * easedProgress; // 0.1から2.0にスケーリング
        } else {
          // 次の0.75秒：通常サイズに戻る
          const scaleDownProgress = (progress - 0.5) * 2; // 0-1に正規化
          const easeInOutCubic = (t) =>
            t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
          const easedProgress = easeInOutCubic(scaleDownProgress);
          image.scale = 2.0 - easedProgress; // 2.0から1.0にスケーリング
        }

        image.x = 50; // 中央に固定
        image.y = 50; // 中央に固定

        if (progress >= 1) {
          image.animationCompleted = true;
          image.scale = 1;
          // 初期速度を設定
          image.velocityX = (Math.random() - 0.5) * 0.5;
          image.velocityY = (Math.random() - 0.5) * 0.5;
          setNewImageState({ id: null, state: null });
        }
      } else {
        // 通常の動き
        const baseVelocity = 0.3;
        const scaledVelocityX = image.velocityX * baseVelocity * currentSpeed;
        const scaledVelocityY = image.velocityY * baseVelocity * currentSpeed;

        const noiseScale = 0.05;
        const noiseX =
          noise2D(currentTime * 0.001 + image.offset, 0) *
          noiseScale *
          currentSpeed;
        const noiseY =
          noise2D(currentTime * 0.001 + image.offset + 1000, 0) *
          noiseScale *
          currentSpeed;

        if (image.type === "walk") {
          // 歩行タイプの動き（変更なし）
          image.y = groundPosition;
          image.x += scaledVelocityX + noiseX;

          if (image.x <= -5 || image.x >= 95) {
            image.velocityX *= -1;
            image.x = Math.max(-5, Math.min(95, image.x));
            image.flipped = !image.flipped;
          }
        } else if (image.type === "fly") {
          // 飛行タイプの動き（修正）
          image.x += scaledVelocityX + noiseX;
          image.y += scaledVelocityY + noiseY;

          // X軸の制限（変更なし）
          if (image.x <= -5 || image.x >= 95) {
            image.velocityX *= -1;
            image.x = Math.max(-5, Math.min(95, image.x));
            image.flipped = !image.flipped;
          }

          // Y軸の制限を地面の位置に基づいて設定
          const maxHeight = 5; // 画面上端からの最小距離（%）
          const minHeight = groundPosition; // 地面の位置

          if (image.y <= maxHeight || image.y >= minHeight) {
            image.velocityY *= -1;
            image.y = Math.max(maxHeight, Math.min(minHeight, image.y));
          }
        }

        if (!image.directionChangeTimer) image.directionChangeTimer = 0;
        image.directionChangeTimer -= 1;
        if (image.directionChangeTimer <= 0) {
          image.velocityX = (Math.random() - 0.5) * 1;
          image.velocityY = (Math.random() - 0.5) * 1;
          image.directionChangeTimer = Math.random() * 50 + 25;
        }

        if (currentTime >= image.nextMovementUpdate) {
          image.lastMovementUpdate = currentTime;
          image.nextMovementUpdate = currentTime + Math.random() * 3000 + 1000;
          image.velocityX += (Math.random() - 0.5) * 0.2;
          image.velocityY += (Math.random() - 0.5) * 0.2;
        }

        if (image.specialMovement) {
          image.globalScale = image.specialScale || image.globalScale;
        } else {
          const MIN_SCALE = 0.6;
          const MAX_SCALE = 1.3;

          image.globalScale += image.scaleDirection * image.scaleSpeed;
          if (image.globalScale > MAX_SCALE) {
            image.globalScale = MAX_SCALE;
            image.scaleDirection *= -1;
          } else if (image.globalScale < MIN_SCALE) {
            image.globalScale = MIN_SCALE;
            image.scaleDirection *= -1;
          }
        }

        const movement = MOVEMENT_SETTINGS[image.movement];
        if (movement) {
          const {
            x = 0,
            y = 0,
            rotation,
            scaleX,
            scaleY,
          } = movement(
            currentTime,
            amplitudeFactor,
            image.offset,
            image.phaseOffset
          );

          image.x += x;
          image.y += y;

          if (rotation !== undefined) {
            // movement関数がrotationを返している場合、その値を使用
            image.rotation = rotation;
          } else if (image.movement === "spin") {
            // spinの特別な処理
            const baseRotationSpeed = 0.5;
            const spinSpeedFactor = amplitudeFactor;
            const deltaTime =
              currentTime - (image.lastUpdateTime || currentTime);
            image.lastUpdateTime = currentTime;
            const rotationChange =
              baseRotationSpeed * spinSpeedFactor * deltaTime;
            image.rotation = (image.rotation + rotationChange) % 360;
          } else {
            // rotationをリセット
            if (imageRef.rotationResetProgress < 1) {
              imageRef.rotationResetProgress += 0.05;
              image.rotation =
                imageRef.rotationResetStart *
                (1 - imageRef.rotationResetProgress);
            } else {
              image.rotation = 0;
            }
          }

          // スケーリングの処理
          if (scaleX !== undefined && scaleY !== undefined) {
            image.scaleX = scaleX * image.globalScale;
            image.scaleY = scaleY * image.globalScale;
          } else {
            image.scaleX = image.scaleY = image.globalScale;
          }
        }

        if (image.movement === "float") {
          if (image.y < -10) image.y = 110;
          if (image.y > 110) image.y = -10;
        }
      }

      // 特殊な動きを適用
      image = applySpecialMovement(image, currentTime);

      return {
        ...image,
        x: image.x,
        y: image.y,
        rotation: image.rotation || 0,
        scaleX: (image.scaleX || image.scale || 1) * image.globalScale,
        scaleY: (image.scaleY || image.scale || 1) * image.globalScale,
        flipped: image.flipped,
        animationCompleted: image.animationCompleted,
      };
    },
    [groundPosition]
  );

  // useEffect: アニメーションループを設定し、各フレームで画像を移動
  useEffect(() => {
    let lastTime = performance.now();
    let frameCount = 0;

    function moveImages(currentTime) {
      const deltaTime = currentTime - lastTime;
      frameCount++;

      if (deltaTime >= 1000) {
        frameCount = 0;
        lastTime = currentTime;
      }

      const updatedImages = Object.values(imagesRef.current).map((image) =>
        moveImage(image)
      );

      imagesRef.current = updatedImages.reduce((acc, img) => {
        acc[img.id] = img;
        return acc;
      }, {});

      setImages(updatedImages);
      animationRef.current = requestAnimationFrame(moveImages);
    }

    animationRef.current = requestAnimationFrame(moveImages);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [moveImage]);

  const handleAllowAudio = useCallback(() => {
    setShowAudioPermissionDialog(false);
    setUserAllowedAudio(true);
    if (bgmAudio) {
      playAudio();
    }
  }, [playAudio, bgmAudio]);

  const handleDenyAudio = () => {
    setShowAudioPermissionDialog(false);
    setUserAllowedAudio(false);
  };

  // ViewPage関数のreturn文の直前に以下を追加
  if (showAudioPermissionDialog) {
    return (
      <AudioPermissionDialog
        onAllow={handleAllowAudio}
        onDeny={handleDenyAudio}
      />
    );
  }
  if (!isSharedViewValid) {
    return <div>Error: この共有ビューは存在しないか、削除されました。</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-page-container">
      {backgroundFile &&
        (backgroundFileType && backgroundFileType.startsWith("image/") ? (
          <img src={backgroundFile} alt="背景" className="background-media" />
        ) : (
          <video
            src={backgroundFile}
            autoPlay
            loop
            muted
            playsInline
            className="background-media"
          >
            <source src={backgroundFile} type={backgroundFileType} />
            Your browser does not support the video tag.
          </video>
        ))}
      <div className="images-wrapper">
        {images.map((image) => {
          if (!visibleImages[image.id]) return null;
          const isNewImage = image.id === newImageStateRef.current.id;

          return (
            <div
              key={image.id}
              className={`image-container ${
                isNewImage ? "new-image-animation" : ""
              }`}
              style={{
                left: `${image.x}%`,
                top: `${image.y}%`,
                transform: `
                  translate(-50%, -50%)
                  scale(${image.scale})
                  rotateY(${image.zRotation || 0}deg)
                `,
              }}
            >
              <img
                src={image.processedUrl}
                alt="Processed"
                className={`animated-image ${image.movement} ${image.size}`}
                style={{
                  transform: `scale(${image.scaleX}, ${image.scaleY}) rotate(${
                    image.rotation
                  }deg) scaleX(${image.flipped ? -1 : 1})`,
                  filter: image.specialEffect || "none",
                }}
              />
              {image.emote && (
                <div className="emote">
                  {image.emote.type === "text" ? (
                    image.emote.content
                  ) : (
                    <img
                      src={`/images/emotes/${image.emote.content}.svg`}
                      alt="Emote"
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="image-counter">
        <span>お絵かきの数</span>
        <p>{Object.values(visibleImages).filter(Boolean).length}</p>
      </div>
    </div>
  );
}

export default ViewPage;
