// frontend/src/components/HeaderComponent.js

import React from "react";
import { Link } from "react-router-dom";

const HeaderComponent = ({ onSignUpClick = () => {}, showSignUpButton = true }) => {
  return (
    <header className="header">
      <div className="inner">
        <div className="logo-container">
          <Link to="/">
            <img
              src="/images/lp/logo.svg"
              alt="ぬりえもんロゴ"
              className="logo"
            />
          </Link>
        </div>
        {showSignUpButton && ( // showSignUpButton が true の場合のみボタンを表示
          <div className="header-buttons">
            <Link to="/login" className="button outline-button">
              ログイン
            </Link>
            <button className="button signup-button" onClick={onSignUpClick}>
              アカウント申請
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderComponent;
