import React from "react";

const FooterComponent = () => {
  return (
    <footer className="footer">
      <div className="inner">
        <div className="footer__links">
          <a
            href="https://greensnap.jp/company/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            運営会社
          </a>
          <a href="/terms-of-service">利用規約</a>
          <a
            href="https://greensnap.jp/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
        </div>
        <div className="footer__copyright">
          &copy; {new Date().getFullYear()} GreenSnap. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
