import React, { useState, useCallback, useEffect, useRef } from "react";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import HeaderComponent from "./HeaderComponent";

function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUserStatus, checkUserStatus } = useAuth();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      console.log("handleLogin called");
      setIsLoading(true);
      setMessage("");

      try {
        console.log("Attempting sign in");
        console.log("Email:", email, "Password:", password);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const userStatus = await checkUserStatus(userCredential.user.uid);

        if (userStatus !== "active") {
          console.log("User status is not active, signing out");
          await signOut(auth);
          if (mountedRef.current) {
            setMessage(
              "このアカウントは現在ログインできません。管理者にお問い合わせください。"
            );
          }
        } else {
          setUserStatus("active");
          navigate("/upload");
        }
      } catch (error) {
        console.error("Authentication error:", error);
        console.log("Error code:", error.code);
        console.log("Error message:", error.message);
        console.log("Error object:", JSON.stringify(error));
        let errorMessage = "認証エラーが発生しました。もう一度お試しください。";

        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "無効なメールアドレスです。";
            break;
          case "auth/user-disabled":
            errorMessage =
              "このアカウントは無効化されています。管理者にお問い合わせください。";
            break;
          case "auth/user-not-found":
            errorMessage =
              "このメールアドレスに対応するアカウントが見つかりません。";
            break;
          case "auth/wrong-password":
            errorMessage = "パスワードが間違っています。";
            break;
          case "auth/too-many-requests":
            errorMessage =
              "アカウントが一時的にロックされました。しばらくしてから再度お試しください。";
            break;
          case "auth/invalid-credential":
            errorMessage = "無効な認証情報です。もう一度お試しください。";
            break;
          default:
            errorMessage = `認証エラー: ${error.message}`;
        }
        console.log("Updating error message in component state");
        if (mountedRef.current) {
          setMessage(errorMessage);
          console.log("Error message set in component state");
        } else {
          console.log("Component unmounted, error message update skipped");
        }
      } finally {
        setIsLoading(false);
        console.log(
          "handleLogin completed. IsLoading state has been set to false."
        );
      }
    },
    [email, password, navigate, setUserStatus, checkUserStatus, mountedRef]
  );

  return (
    <>
      <HeaderComponent showSignUpButton={false} />{" "}
      <div className="auth-page">
        <div className="container">
          <div className="auth-form">
            <h2>ログイン</h2>
            {message && (
              <p
                className="message"
                style={{
                  color: "red",
                  backgroundColor: "#f8f8f8",
                  padding: "10px",
                  borderRadius: "5px",
                  marginBottom: "15px",
                }}
              >
                {message}
              </p>
            )}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email">メールアドレス</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">パスワード</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>
              <button type="submit" disabled={isLoading}>
                {isLoading ? "処理中..." : "ログイン"}
              </button>
            </form>
            <p className="toggle-auth">
              アカウントをお持ちでないですか？
              <br /> <Link to="/signup">アカウント作成</Link>
            </p>
            <p className="forgot-password">
              パスワードをお忘れの方{" "}
              <Link to="/reset-password">パスワードをリセット</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
