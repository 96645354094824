import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import ShareUrlComponent from "./ShareUrlComponent";

function Navigation() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    if (currentUser) {
      fetchShareUrl();
    }
  }, [currentUser]);

  const fetchShareUrl = async () => {
    try {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists() && docSnap.data().sharedViewId) {
        setShareUrl(
          `${window.location.origin}/view/${docSnap.data().sharedViewId}`
        );
      }
    } catch (error) {
      console.error("Error fetching share URL:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
      alert("ログアウトしました。");
    } catch (error) {
      console.error("ログアウト中にエラーが発生しました:", error);
      alert("ログアウトに失敗しました。");
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav>
      <div className="container">
        <div className="nav-header">
          <input
            type="checkbox"
            id="checkbox"
            checked={isMenuOpen}
            onChange={toggleMenu}
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bars" id="bar1"></div>
            <div className="bars" id="bar2"></div>
            <div className="bars" id="bar3"></div>
          </label>
        </div>
        <div className="nav-box">
          <div className="logo">
            <Link to="/upload">
              <img
                src="/images/lp/logo.svg"
                alt="Meriemon logo"
                className="logo"
              />
            </Link>
          </div>
          <ul className={isMenuOpen ? "nav-menu open" : "nav-menu"}>
            <li>
              <Link to="/upload" className="nav-link" onClick={toggleMenu}>
                アップロード
              </Link>
            </li>
            <li>
              <Link to="/gallery" className="nav-link" onClick={toggleMenu}>
                お絵かき一覧
              </Link>
            </li>
            <li>
              <a
                href="/view"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
                onClick={toggleMenu}
              >
                スクリーン表示
              </a>
            </li>
            <li className="share-url-container">
              <ShareUrlComponent showTooltip={true} />
            </li>
            <li className="user-info">
              {currentUser ? (
                <>
                  <span>ID:{currentUser.email}</span>
                  <button
                    onClick={handleLogout}
                    className="logout-button"
                    aria-label="ログアウト"
                  >
                    <i className="fa-solid fa-right-from-bracket"></i>
                    <span className="tooltip">ログアウト</span>
                  </button>
                </>
              ) : (
                <Link to="/auth" onClick={toggleMenu}>
                  ログイン/新規登録
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
