// frontend/src/components/TermsOfService.js

import React from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-page">
      <HeaderComponent showSignUpButton={false} />{" "}
      <div className="terms-container">
        <h1>利用規約</h1>
        <p>
          この利用規約（以下、「本規約」といいます。）は、GreenSnap株式会社（以下、「当社」といいます。）が提供するサービス「ぬりえもん」（以下、「本サービス」といいます。）の利用条件を定めるものです。ユーザーの皆様（以下、「ユーザー」といいます。）には、本規約に従って本サービスをご利用いただきます。
        </p>

        <h2>第1条（適用）</h2>
        <p>
          本規約は、本サービスの利用に関わる一切の関係に適用されるものとします。
        </p>
        <p>
          当社が本サービスに関して個別に定める利用条件（以下、「個別利用条件」といいます。）および本規約の別段の定めがある場合には、個別利用条件の規定が優先して適用されるものとします。
        </p>

        <h2>第2条（利用登録）</h2>
        <p>
          本サービスにおいては、利用登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することにより、利用登録が完了するものとします。
        </p>
        <p>
          当社は、以下のいずれかに該当する場合、利用登録の申請を承認しないことがあります。
        </p>
        <ul>
          <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
          <li>本規約に違反したことがある者からの申請である場合</li>
          <li>その他、当社が利用登録を相当でないと判断した場合</li>
        </ul>

        <h2>第3条（ユーザーIDおよびパスワードの管理）</h2>
        <p>
          ユーザーは、自己の責任において本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
        </p>
        <p>
          ユーザーは、いかなる場合にもユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
        </p>

        <h2>第4条（禁止事項）</h2>
        <p>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </p>
        <ul>
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>
            当社、本サービスの他のユーザー、またはその他第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為
          </li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>本サービスを通じて得た情報を商業目的で利用する行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ul>

        <h2>第5条（コンテンツの取扱）</h2>
        <p>
          利用者は、本サービスにアップロードする等して当社の管理するサーバーに保存させた文章、画像、動画、音楽、音声その他一切の情報（以下「コンテンツ」といいます。）について、本サービスにアップロード等を行うにあたり、これを行うための権利（自ら著作権、肖像権等、又は、他者のこれらの権利を利用する権利）を有していること、アップロード等を行うことで他者の権利を侵害しないこと、及び本条第5項で定める許諾を行う権利があることを、表明し保証します。
        </p>
        <p>
          当社は、法令又は本利用規約等の遵守状況等を確認する必要がある場合、コンテンツの内容を確認することができるものとします。ただし、当社はかかる確認を行なう義務を負うものではないものとします。
        </p>
        <p>
          利用者は、コンテンツの一部又は全部に関し、発生しうる全ての著作権（著作権法第27条及び第28条に定める権利を含みます。）について、目的を問わず、無償かつ無制限に利用できる権利を当社に対して許諾することについて同意します。
        </p>
        <p>
          当社は、利用者がアップロード等した利用者コンテンツが法令若しくは本利用規約等に違反し又は違反するおそれのあると当社が認めた場合、その他業務上の必要性がある場合には、あらかじめ参加者に通知することなく、当社の管理するサーバーからコンテンツを削除する等の方法により、本サービスにおいてコンテンツを利用できないようにすることができます。
        </p>
        <p>
          当社は、本サービスを、当社又は第三者が運営するウェブサイト、メディア、ソフトウェア、アプリケーション、プロダクト、ドキュメント等で紹介することできるものとし、この場合、利用者は、自らアップロード等行ったコンテンツについて、当社及び上記第三者が必要な範囲でこれを利用することを許諾し、著作物について参加者は著作者人格権を行使しないものとするほか、利用者に対価が発生しないことを容認します。
        </p>

        <h2>第6条（利用範囲）</h2>
        <p>
          利用者は、有効に利用者として登録されている期間内に限り、本規約の目的の範囲内かつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。
        </p>
        <p>
          本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、利用者の費用と責任において行うものとします。
        </p>

        <h2>第7条（本サービスの提供の停止等）</h2>
        <p>
          当社は、以下の場合には、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        </p>
        <ul>
          <li>
            本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
          </li>
          <li>
            地震、落雷、火災、風水害、停電または天災などの不可抗力により本サービスの提供が困難となった場合
          </li>
          <li>コンピュータまたは通信回線等が事故により停止した場合</li>
          <li>その他、当社が本サービスの提供が困難と判断した場合</li>
        </ul>

        <h2>第8条（利用料金および支払方法）</h2>
        <p>
          本サービスの有料部分の利用料金は、当社が別途定め、本ウェブサイト上に表示するものとします。
        </p>
        <p>
          ユーザーは、当社が定める方法により、当社に対して利用料金を支払うものとします。
        </p>

        <h2>第9条（サービス内容の変更等）</h2>
        <p>
          当社は、ユーザーに通知することなく、本サービスの内容を変更し、または本サービスの提供を中止することができるものとします。
        </p>

        <h2>第10条（免責事項）</h2>
        <p>
          1.
          当社は、本サービスに事実上または法律上の瑕疵がないことを保証するものではありません。当社は、本サービスの提供が中断されないこと、本サービスにエラーがないこと、本サービスのセキュリティが確保されていることなどについても一切保証しません。
        </p>
        <p>
          2.
          当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社の故意または重過失による場合を除きます。
        </p>
        <p>
          3.
          当社は、本サービスに関連してユーザー間またはユーザーと第三者との間において生じた取引、連絡または紛争について、一切の責任を負いません。
        </p>

        <h2>第11条（著作権）</h2>
        <p>
          1.
          ユーザーが本サービスを利用して投稿または送信したコンテンツに関する著作権その他の知的財産権は、ユーザー自身に帰属します。
        </p>
        <p>
          2.
          ユーザーは、当社に対して、本サービスを利用して投稿または送信したコンテンツを、当社が本サービスの提供および宣伝のために使用する非独占的、無償、永続的、譲渡不可、サブライセンス不可の権利を許諾するものとします。
        </p>

        <h2>第12条（プライバシーポリシー）</h2>
        <p>
          当社は、本サービスの利用にあたり、別途定める「プライバシーポリシー」に従い、ユーザーの個人情報を適切に取り扱います。
        </p>

        <h2>第13条（利用規約の変更）</h2>
        <p>
          1.
          当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
        </p>
        <p>
          2.
          本規約の変更後、本サービスの利用を継続する場合には、ユーザーは変更後の本規約に同意したものとみなされます。
        </p>

        <h2>第14条（準拠法・裁判管轄）</h2>
        <p>1. 本規約の解釈にあたっては、日本法を準拠法とします。</p>
        <p>
          2.
          本サービスに関連して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄裁判所とします。
        </p>

        <div className="back-to-home">
          <Link to="/">ホームに戻る</Link>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
